import { reactive, toRefs, getCurrentInstance, watch } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    category: [],
    serverDt: '',
    params: {
      categoryId: '',
      title: '',
      usePeriod: 30,
      minPrice: '',
      maxPrice: '',
      searchWord: '',
      notiDt: '',
      notiYn: 'N',
      openYn: 'N',
      publicYn: 'N'
    }
  })
  const fnCategoryList = async () => {
    const res = await proxy.$PartySvc.postPartyAddForm()
    if (res.resultCode === '0000') {
      state.category = res.category
      state.serverDt = res.serverDt
    } else {
      alert(res.resultMsg)
    }
  }
  const isValid = () => {
    if (proxy.$Util.isEmpty(state.params.categoryId)) {
      alert('카테고리를 선택해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.params.title)) {
      alert('신청 제목을 입력해주세요.')
      return false
    }
    if (
      proxy.$Util.isEmpty(state.params.minPrice) ||
      Number(state.params.minPrice) < 1
    ) {
      alert('0원 이상의 가격을 입력해주세요.')
      return false
    }
    if (
      proxy.$Util.isEmpty(state.params.maxPrice) ||
      Number(state.params.maxPrice) < 1
    ) {
      alert('0원 이상의 가격을 입력해주세요.')
      return false
    }
    if (Number(state.params.maxPrice) < Number(state.params.minPrice)) {
      alert('최대 가격은 최소 가격보다 낮을 수 없습니다.')
      return false
    }
    if (proxy.$Util.isEmpty(state.params.searchWord)) {
      alert('검색어를 입력해주세요.')
      return false
    }
    return true
  }
  const fnSave = async () => {
    if (!isValid()) return
    const res = await proxy.$PartySvc.postPartyUpdateHope(state.params)
    if (res.resultCode === '0000') {
      alert('등록되었습니다.')
      await router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }
  const fnDelete = async () => {
    const res = await proxy.$PartySvc.postDeleteHope()
    if (res.resultCode === '0000') {
      alert('설정 알림이 삭제되었습니다')
      await router.push({ path: '/my-page/home' })
    } else {
      alert(res.resultMsg)
    }
  }
  const fnDetail = async () => {
    const res = await proxy.$MyPageSvc.postMyPartyHopeDetail()
    if (res.resultCode === '0000') {
      proxy.$_.merge(state.params, res.detail)
    } else {
      alert(res.resultMsg)
    }
  }
  /** watch **/
  watch(
    () => state.params.minPrice,
    val => {
      if (!proxy.$Util.isEmpty(val) && val.length > 9) {
        state.params.minPrice = val.substr(0, 9)
      }
    }
  )
  watch(
    () => state.params.maxPrice,
    val => {
      if (!proxy.$Util.isEmpty(val) && val.length > 9) {
        state.params.maxPrice = val.substr(0, 9)
      }
    }
  )
  const init = async () => {
    await fnCategoryList()
    await fnDetail()
  }
  init()
  return { ...toRefs(state), fnSave, fnDelete }
}
